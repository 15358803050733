const React = require('react');

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (window.dataLayer !== undefined) {
    window.dataLayer.push({
      event: 'onRouteChange',
      path: location.pathname,
    });
    window.dataLayer.push({
      gtm_loaded: true,
    });
  }
};
